"use client";
import React, { useEffect } from "react";
import {
  usePromoCode,
  setItem,
  getItem,
  DISCOUNT_CODE_KEY,
} from "@/data/helpers/promoCode/hooks";
import type { RetoolPromoCode } from "@/types/gql/graphql";
import Line from "./Line";
import { renderLink } from "./utils";

export interface TopBannerProps {
  promoCode?: RetoolPromoCode;
}

const DiscountBanner: React.FC<TopBannerProps> = ({ promoCode }) => {
  const { discountCampaign, text } = usePromoCode();

  // set default code if not stored
  const storedKey = getItem(DISCOUNT_CODE_KEY);
  useEffect(() => {
    if (!storedKey && promoCode?.code) {
      setItem(DISCOUNT_CODE_KEY, promoCode?.code);
    }
  }, [storedKey, promoCode?.code]);

  if (discountCampaign?.code) {
    return (
      <Line
        text={text ?? discountCampaign.heroLine ?? "..."}
        url={renderLink(discountCampaign.code, discountCampaign.heroLink)}
      />
    );
  }

  if (promoCode?.heroLine && promoCode?.code) {
    return (
      <Line
        text={text ?? promoCode.heroLine}
        url={renderLink(promoCode.code, promoCode.heroLink)}
      />
    );
  }

  return null;
};

export default DiscountBanner;
