"use client";

import { sendGTMEvent } from "@next/third-parties/google";
import { useSearchParams } from "next/navigation";
import React, { useEffect, useRef } from "react";

const GiftMembershipConfirmationTrackingComponent: React.FC<{
  id: string;
}> = () => {
  const searchParams = useSearchParams();
  const discountCode = searchParams?.get("discount_code") ?? undefined;
  const emailSha = searchParams?.get("ceh") ?? undefined;
  const sku = searchParams?.get("sku") ?? undefined;
  const orderId = searchParams?.get("order_id") ?? undefined;
  const totalPrice = searchParams?.get("total_price") ?? undefined;
  const hasSentEvent = useRef(false);

  useEffect(() => {
    if (!orderId || hasSentEvent.current) {
      return;
    }

    hasSentEvent.current = true;

    sendGTMEvent({
      email_sha256: emailSha,
    });

    sendGTMEvent({
      event: "subscription_checkout_completed",
      ecommerce: {
        transaction_id: orderId,
        currency: "GBP", // TODO: Confirm once EUR is supported
        value: totalPrice,
        coupon: discountCode,
        items: [
          {
            item_id: sku,
            item_name: "Gift Membership",
            index: 0,
            item_brand: "Craft Gin Club",
            price: totalPrice,
            item_quantity: 1,
          },
        ],
      },
    });
  }, [discountCode, emailSha, orderId, sku, totalPrice]);

  return (
    <>
      {/* Intentionally left blank as this is just used for adding tracking */}
    </>
  );
};

export default GiftMembershipConfirmationTrackingComponent;
