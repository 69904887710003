import { gql } from "@apollo/client";

export const variantsOnProduct = gql`
  fragment variantsOnProduct on Product {
    variants(first: 1) {
      edges {
        node {
          id
          price {
            amount
          }
          image {
            url
          }
        }
      }
    }
  }
`;

export const collectionsOnProduct = gql`
  fragment collectionsOnProduct on Product {
    collections(first: 100) {
      edges {
        node {
          handle
        }
      }
    }
  }
`;

export const CartBuyerFragment = gql`
  fragment cartBuyer on Cart {
    buyerIdentity {
      email
      phone
      customer {
        id
        tags
      }
      countryCode
      deliveryAddressPreferences {
        ... on MailingAddress {
          address1
          address2
          city
          provinceCode
          countryCodeV2
          zip
        }
      }
    }
  }
`;

export const CartDetailFragment = gql`
  ${variantsOnProduct}
  ${collectionsOnProduct}
  fragment cartDetail on Cart {
    id
    createdAt
    updatedAt
    checkoutUrl
    lines(first: 250) {
      edges {
        node {
          id
          quantity
          attributes {
            key
            value
          }
          cost {
            subtotalAmount {
              amount
              currencyCode
            }
            totalAmount {
              amount
              currencyCode
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              price {
                amount
                currencyCode
              }
              image {
                url
              }
              product {
                title
                tags
                handle
                ...variantsOnProduct
                ...collectionsOnProduct
              }
            }
          }
        }
      }
    }
    totalQuantity
    attributes {
      key
      value
    }
    cost {
      totalAmount {
        amount
        currencyCode
      }
      # The estimated amount, before taxes and discounts, for the customer to pay at checkout.
      subtotalAmount {
        amount
        currencyCode
      }
      # The estimated tax amount for the customer to pay at checkout.
      totalTaxAmount {
        amount
        currencyCode
      }
      # The estimated duty amount for the customer to pay at checkout.
      totalDutyAmount {
        amount
        currencyCode
      }
      checkoutChargeAmount {
        amount
        currencyCode
      }
    }
  }
`;

export const CreateCartMutation = gql`
  ${CartDetailFragment}
  ${CartBuyerFragment}
  mutation CreateCart($input: CartInput!) {
    cartCreate(input: $input) {
      cart {
        ...cartDetail
        ...cartBuyer
      }
    }
  }
`;

export const GetCartQuery = gql`
  ${CartDetailFragment}
  ${CartBuyerFragment}
  query GetCartQuery($cartId: ID!) {
    cart(id: $cartId) {
      ...cartDetail
      ...cartBuyer
    }
  }
`;

export const AddCartLinesMutation = gql`
  ${CartDetailFragment}
  ${CartBuyerFragment}
  mutation AddCartLinesMutation($lines: [CartLineInput!]!, $cartId: ID!) {
    cartLinesAdd(lines: $lines, cartId: $cartId) {
      cart {
        ...cartDetail
        ...cartBuyer
      }
    }
  }
`;

export const UpdateCartLinesMutation = gql`
  ${CartDetailFragment}
  ${CartBuyerFragment}
  mutation UpdateCartLinesMutation(
    $cartId: ID!
    $lines: [CartLineUpdateInput!]!
  ) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...cartDetail
        ...cartBuyer
      }
    }
  }
`;

export const GetCheckoutUrlQuery = gql`
  query GetCheckoutUrlQuery($cartId: ID!) {
    cart(id: $cartId) {
      checkoutUrl
    }
  }
`;

export const RemoveLineMutation = gql`
  ${CartDetailFragment}
  ${CartBuyerFragment}
  mutation RemoveLineMutation($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...cartDetail
        ...cartBuyer
      }
    }
  }
`;

export const CartBuyerIdentityUpdateMutation = gql`
  ${CartDetailFragment}
  ${CartBuyerFragment}
  mutation CartBuyerIdentityUpdateMutation(
    $cartId: ID!
    $buyerIdentity: CartBuyerIdentityInput!
  ) {
    cartBuyerIdentityUpdate(cartId: $cartId, buyerIdentity: $buyerIdentity) {
      cart {
        ...cartDetail
        ...cartBuyer
      }
    }
  }
`;
