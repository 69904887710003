import React from "react";
import Image from "next/image";
import reviewsLogo from "@/components/MemberReviews/reviewsio-logo.svg";
import { Rating } from "@cgc/ui";

const Skeleton = () => (
  <>
    <div className="mb-[30px] flex flex-row flex-wrap items-center justify-center gap-[20px]">
      <div className="h-2 w-[180px] animate-pulse rounded-full bg-gray-300" />
      <Rating>
        <Rating.Star />
        <Rating.Star />
        <Rating.Star />
        <Rating.Star />
        <Rating.Star />
      </Rating>
      <div className="h-2 w-[150px] animate-pulse rounded-full bg-gray-300" />
      <div className="h-2 w-[150px] animate-pulse rounded-full bg-gray-300" />
      <Image src={reviewsLogo} alt={"Reviews.io Logo"} />
    </div>
    <div className="no-scrollbar relative mb-5 flex w-full snap-x snap-mandatory gap-5 overflow-x-auto scroll-smooth">
      {Array.from({ length: 5 }).map((_, item) => (
        <div
          key={`skeleton_${item}`}
          className="flex h-[239px] w-[265px] shrink-0 snap-center snap-always flex-col items-center rounded-[10px] bg-gray-300 px-[15px] py-[30px] text-white"
        >
          <div className="mb-2 h-5 w-[150px] animate-pulse rounded-full bg-gray-100" />
          <Rating className="mb-3 animate-pulse">
            <Rating.Star white />
            <Rating.Star white />
            <Rating.Star white />
            <Rating.Star white />
            <Rating.Star white />
          </Rating>
          <div className="w-full animate-pulse">
            <div className="mx-auto mb-2 h-3 w-11/12 rounded-full bg-gray-100" />
            <div className="mx-auto mb-2 h-3 w-11/12 rounded-full bg-gray-100" />
            <div className="mx-auto mb-2 h-3 w-11/12 rounded-full bg-gray-100" />
            <div className="mx-auto mb-2 h-3 w-11/12 rounded-full bg-gray-100" />
          </div>
          <div className="mx-auto mb-2 mt-5 h-3 w-8/12 animate-pulse rounded-full bg-gray-100" />
        </div>
      ))}
    </div>
  </>
);

export default Skeleton;
