import { gql } from "@apollo/client/core";

import {
  variantsOnProduct,
  collectionsOnProduct,
} from "@/data/helpers/cart/queries";

export const mediaFieldsByType = gql`
  fragment mediaFieldsByType on Media {
    ... on ExternalVideo {
      id
      embeddedUrl
    }
    ... on MediaImage {
      image {
        url
      }
    }
    ... on Model3d {
      sources {
        url
        mimeType
        format
        filesize
      }
    }
    ... on Video {
      sources {
        url
        mimeType
        format
        height
        width
      }
    }
  }
`;

export const ProductsQuery = gql`
  ${variantsOnProduct}
  ${collectionsOnProduct}
  query getProducts($limit: Int = 5, $offset: String, $query: String) {
    products(first: $limit, after: $offset, query: $query) {
      edges {
        node {
          id
          title
          handle
          tags
          vendor
          totalInventory
          ...variantsOnProduct
          ...collectionsOnProduct
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const ProductQuery = gql`
  ${variantsOnProduct}
  ${collectionsOnProduct}
  ${mediaFieldsByType}
  query Product($handle: String) {
    product(handle: $handle) {
      id
      title
      description
      featuredImage {
        url
      }
      seo {
        title
        description
      }
      vendor
      ...variantsOnProduct
      ...collectionsOnProduct
      media(first: 10) {
        edges {
          node {
            mediaContentType
            alt
            ...mediaFieldsByType
          }
        }
      }
    }
  }
`;

export const GiftMembershipProductQuery = gql`
  query GiftMembershipProduct($handle: String) {
    product(handle: $handle) {
      id
      title
      productType
      variants(first: 5) {
        nodes {
          id
          price {
            amount
          }
          title
        }
      }
    }
  }
`;
