import Link from "@/components/Link";
import * as prismicH from "@prismicio/helpers";
import { CommonComponent } from "@/components/types";
import { Heading, Paragraph } from "@cgc/ui";
import { clsx } from "@cgc/ui";

type ComponentProps = {
  isDark?: boolean;
};

export const leftComponents = () => ({
  heading1: ({ children }: CommonComponent) => {
    const blogArticleHeadingProps = {
      as: "div" as "div",
      className: "!mb-0 text-cgc-blue mr-1",
    };

    return (
      <>
        <Paragraph {...blogArticleHeadingProps} size="sub" mobileOnly>
          {children}
        </Paragraph>
        <Heading {...blogArticleHeadingProps} size="h4" display desktopOnly>
          {children}
        </Heading>
      </>
    );
  },
  paragraph: ({ children }: CommonComponent) => (
    <>
      <Paragraph className="text-cgc-blue !mb-0" as="div" size="sub" mobileOnly>
        {children}
      </Paragraph>
      <Heading
        className="text-cgc-blue !mb-0"
        as="div"
        size="h4"
        display
        desktopOnly
      >
        {children}
      </Heading>
    </>
  ),
});

export const subcopyComponents = () => ({
  paragraph: ({ children }: CommonComponent) => (
    <>
      <Paragraph mobileOnly size="p3">
        {children}
      </Paragraph>
      <Paragraph desktopOnly size="p1">
        {children}
      </Paragraph>
    </>
  ),
});

export const rightComponents = ({ isDark }: ComponentProps) => ({
  heading2: ({ children }: CommonComponent) => (
    <Paragraph size="sub" className="mb-2">
      {children}
    </Paragraph>
  ),
  paragraph: ({ children }: CommonComponent) => (
    <>
      <Paragraph mobileOnly size="p3">
        {children}
      </Paragraph>
      <Paragraph desktopOnly size="p2">
        {children}
      </Paragraph>
    </>
  ),
  hyperlink: ({ node, children }: CommonComponent & { node: any }) => (
    <Link
      href={prismicH.asLink(node.data)!}
      className={clsx(isDark ? "text-cgc-white" : "primary")}
    >
      {children}
    </Link>
  ),
});

export const successMessageComponents = () => ({
  heading2: ({ children }: CommonComponent) => (
    <Paragraph size="sub" className={clsx("mb-1.5 h-[28px]")}>
      {children}
    </Paragraph>
  ),
  paragraph: ({ children }: CommonComponent) => (
    <>
      <Paragraph mobileOnly size="p2">
        {children}
      </Paragraph>
      <Paragraph desktopOnly size="p1">
        {children}
      </Paragraph>
    </>
  ),
});
