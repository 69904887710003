import qs from "qs";
import { identity, pickBy } from "lodash";

export const encodeParam = (value: string) =>
  value.split(" ").map(encodeURIComponent).join("+");
export const decodeParam = (value: string) =>
  value.split("+").map(decodeURIComponent).join(" ");

export const addToSearchParams = (
  search: string | undefined,
  params: Record<string, string>,
  cleanEmptyParams = true,
) => {
  const searchObject = qs.parse(search ?? "");
  let result = { ...searchObject, ...params };
  if (cleanEmptyParams) {
    result = pickBy(result, identity);
  }

  return qs.stringify(result);
};
