"use client";
import React from "react";
import { clsx } from "@cgc/ui";
import logo from "./logo.svg";
import Image from "next/image";

const PINTEREST_ROOT = "https://www.pinterest.com/pin/create/button/";

type Props = {
  media: string;
  description: string;
  className?: string;
};

const SaveButton: React.FC<Props> = ({ media, description, className }) => {
  const url = new URL(PINTEREST_ROOT);
  url.searchParams.append("url", encodeURIComponent(window.location.href));
  url.searchParams.append("media", encodeURIComponent(media));
  url.searchParams.append("description", encodeURIComponent(description));

  return (
    <a
      href={url.toString()}
      title="Pin it!"
      className={clsx(
        "absolute mr-auto flex h-[28px] w-[70px] flex-row items-center justify-center gap-x-1 rounded-[4px] bg-[#bd081c] text-base text-white no-underline shadow-lg hover:text-white",
        className,
      )}
      target="_blank"
    >
      <Image src={logo} alt={"Pinterest logo"} width={18} height={18} />
      <span className="pt-1 font-semibold">Save</span>
    </a>
  );
};

export default SaveButton;
