"use client";
import React from "react";
import dynamic from "next/dynamic";
import Image from "next/image";

import { HeroImageProps } from "./types";
import { usePromoCode } from "@/data/helpers/promoCode/hooks";
import { benefitsRenderAllowed } from "./utils";

const HeroImageWithBenefits = dynamic(
  () => import("@/components/ui/Hero/DualColumn/HeroImageWithBenefits"),
  { ssr: false },
);

export default function HeroImage({
  backgroundImage,
  benefits,
}: HeroImageProps) {
  const { code } = usePromoCode();
  if (benefits?.length > 0 && benefitsRenderAllowed(code)) {
    return (
      <HeroImageWithBenefits
        backgroundImage={backgroundImage}
        benefits={benefits}
      />
    );
  }

  return (
    <div className="relative flex min-h-[245px] w-full items-center sm:min-h-[375px] lg:min-h-[245px] lg:w-1/2">
      <Image
        src={backgroundImage}
        alt=""
        priority
        fill
        className="object-cover object-center"
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
      />
    </div>
  );
}
