import { PropsWithChildren } from "react";

import { PromoLine } from "@cgc/ui";

export default function BenefitsPromoLine({ children }: PropsWithChildren) {
  return (
    <PromoLine className="bg-cgc-green-20 h-[45px] overflow-hidden px-[15px] py-2.5">
      {children}
    </PromoLine>
  );
}
