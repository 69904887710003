"use client";
import React from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";

import { Container, Button, Spinner } from "@cgc/ui";
import { components } from "./richTextComponents";

import { useOnSubmit } from "@/components/NewsletterSignup/useOnSubmit";
import TextInputField from "@/components/ui/Form/TextInputField";

export const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter your email"),
});

export type InitialValues = {
  email: string;
  firstName?: string;
  lastName?: string;
};

type NewsletterFormProps = {
  ometriaId: number;
  buttonCopy?: string;
  successMessage?: React.ReactNode;
};

export function NewsletterForm({
  ometriaId,
  buttonCopy,
  successMessage,
}: NewsletterFormProps) {
  const { showSuccess, onSubmit, loading } = useOnSubmit<InitialValues>({
    ometriaId,
  });

  return (
    <Container>
      {!showSuccess ? (
        <Container>
          <Formik
            initialValues={{ email: "", firstName: "", lastName: "" }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <div className="grid grid-cols-1 gap-2.5 lg:grid-cols-4">
                <TextInputField
                  placeholder="First Name"
                  name="firstName"
                  type="text"
                />
                <TextInputField
                  placeholder="Last Name"
                  name="lastName"
                  type="text"
                />
                <TextInputField placeholder="Email" name="email" type="email" />

                <Button
                  type="submit"
                  color="secondary"
                  disabled={loading}
                  className="h-[38px] min-w-[135px]"
                >
                  {loading && (
                    <Spinner className="m-0 mr-3 inline-block text-white" />
                  )}
                  {buttonCopy ?? "Sign-up"}{" "}
                </Button>
              </div>
            </Form>
          </Formik>
        </Container>
      ) : (
        <Container className="text-center">
          {successMessage ??
            components.paragraph({ children: "Thank you for subscribing!" })}
        </Container>
      )}
    </Container>
  );
}
