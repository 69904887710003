import React from "react";
import Link from "@/components/Link";
import { Paragraph, PromoLine } from "@cgc/ui";

type LineProps = {
  text: string;
  url?: string;
};

const Line: React.FC<LineProps> = ({ text, url }) => {
  const promoLine = (
    <Paragraph
      size="sub3"
      className="text-cgc-blue px-4 text-center !font-bold lg:text-lg"
    >
      {text}
    </Paragraph>
  );

  return (
    <PromoLine>
      {url ? (
        <Link href={url} className="secondary plain">
          {promoLine}
        </Link>
      ) : (
        promoLine
      )}
    </PromoLine>
  );
};

export default Line;
