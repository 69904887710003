"use client";

import { useSearchParams } from "next/navigation";
import {
  HTMLProps,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { useOffer } from "./OfferContext";

export function OfferAwareLink({
  url,
  children,
  ...props
}: HTMLProps<HTMLAnchorElement> & PropsWithChildren<{ url: string }>) {
  const { discountForUrl } = useOffer();
  const [composedUrl, setComposedUrl] = useState<string>(url);
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const currentPageSearchParams = useSearchParams();

  useEffect(() => {
    try {
      const baseUrl = new URL(url);

      const queryParams = new URLSearchParams(baseUrl.search);

      // Append the current URLs query params
      for (const [key, value] of currentPageSearchParams.entries()) {
        queryParams.set(key, value);
      }

      const applicableDiscount = discountForUrl(url);

      queryParams.set("discount_code", applicableDiscount.discount);

      baseUrl.search = queryParams.toString();

      setComposedUrl(baseUrl.toString());
    } catch (error) {
      // Deliberately ignore errors
      // URLs that are not valid will not be modified
    }
  }, [url]);

  // Monitor for changes in the URL and if the URL changes, update the composed URL
  // Add an attribute modification observer to the anchor element
  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (anchorRef.current) {
        if (anchorRef.current.href !== composedUrl) {
          const baseUrl = new URL(anchorRef.current.href);

          const queryParams = new URLSearchParams(baseUrl.search);

          // Append the current URLs query params
          for (const [key, value] of currentPageSearchParams.entries()) {
            queryParams.set(key, value);
          }

          const applicableDiscount = discountForUrl(url);

          queryParams.set("discount_code", applicableDiscount.discount);

          baseUrl.search = queryParams.toString();

          setComposedUrl(baseUrl.toString());
        }
      }
    });

    if (anchorRef.current) {
      observer.observe(anchorRef.current, {
        attributes: true,
        attributeFilter: ["href"],
      });
    }

    return () => observer.disconnect();
  }, [anchorRef]);

  return (
    <a href={composedUrl} {...props} ref={anchorRef} data-offer-aware="true">
      {children}
    </a>
  );
}
