"use client";
import dynamic from "next/dynamic";
import type { SliceComponentProps } from "@prismicio/react";
import { PrismicRichText } from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";
import type { Content } from "@prismicio/client";
import { ExtendedSettingsDocument } from "@/components/Navigation/types";
import * as defaultComponents from "@/components/NewsletterSignup/richTextComponents";
import * as componentsBlogArticle from "@/components/Blog/BlogNewsletterSignup/richTextComponents";
import { sliceAnchor } from "@/utils/prismic";

const BlogNewsletterSignup = dynamic(
  () => import("@/components/Blog/BlogNewsletterSignup"),
);
const NewsletterSignup = dynamic(() => import("@/components/NewsletterSignup"));

export type NewsletterSignupSliceProps =
  SliceComponentProps<Content.NewsletterSignupSlice>;

export default function NewsletterSignupSlice({
  slice,
  context,
}: NewsletterSignupSliceProps) {
  const {
    newsletter_title,
    newsletter_sub_title,
    newsletter_subcopy,
    newsletter_tnc,
    newsletter_placeholder,
    newsletter_button_copy,
    newsletter_ometria_id,
    newsletter_success_message,
  } = (context as { settings: ExtendedSettingsDocument }).settings.data;

  const subTitle = prismicH.isFilled.richText(slice.primary.sub_title)
    ? slice.primary.sub_title
    : newsletter_sub_title;
  const subCopy = prismicH.isFilled.richText(slice.primary.subcopy)
    ? slice.primary.subcopy
    : newsletter_subcopy;
  const buttonCopy = (slice.primary.button_copy ??
    newsletter_button_copy) as string;
  const ometriaId = (slice.primary.ometria_id ??
    newsletter_ometria_id) as number;
  const isDark = slice.variation === "dark";
  const isBlogArticle = slice.variation === "blogArticle";

  const NewsletterSignupComponent = isBlogArticle
    ? BlogNewsletterSignup
    : NewsletterSignup;
  const components = isBlogArticle ? componentsBlogArticle : defaultComponents;

  return (
    <NewsletterSignupComponent
      id={sliceAnchor(slice)}
      title={
        <>
          <PrismicRichText
            field={newsletter_title}
            components={components.leftComponents({ isDark })}
          />
          <PrismicRichText
            field={subTitle}
            components={components.leftComponents({ isDark })}
          />
        </>
      }
      subCopy={
        <PrismicRichText
          field={subCopy}
          components={components.subcopyComponents()}
        />
      }
      tnc={
        <PrismicRichText
          field={newsletter_tnc}
          components={components.rightComponents({ isDark })}
        />
      }
      placeholder={newsletter_placeholder as string}
      buttonCopy={buttonCopy}
      buttonId={slice.primary.button_id as string}
      successMessage={
        <PrismicRichText
          field={newsletter_success_message}
          components={components.successMessageComponents()}
        />
      }
      ometriaId={ometriaId}
      dark={isDark}
    />
  );
}
