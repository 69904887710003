"use client";

import { OfferAwareLink } from "@/app/(landing)/offer-utils/OfferAwareLink";
import { OfferAwareText } from "@/app/(landing)/offer-utils/OfferAwareText";
import { Button, Paragraph } from "@cgc/ui";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { useEffect, useRef } from "react";

/**
 * Props for `LandingPageFloatingButtons`.
 */
export type LandingPageFloatingButtonsProps =
  SliceComponentProps<Content.LandingPageFloatingButtonsSlice>;

/**
 * Component for "LandingPageFloatingButtons" Slices.
 */
const LandingPageFloatingButtons = ({
  slice,
}: LandingPageFloatingButtonsProps): JSX.Element => {
  const bannerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = bannerRef.current?.previousElementSibling;
      if (!heroSection || !bannerRef.current) return;

      const heroBottom = heroSection.getBoundingClientRect().bottom;
      if (heroBottom <= 0) {
        bannerRef.current.style.bottom = "0";
      } else {
        bannerRef.current.style.bottom = `-${heroBottom}px`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section
      ref={bannerRef}
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="fixed -bottom-60 left-0 z-50 w-full text-center"
    >
      <div className="flex flex-col">
        <div className="z-10 mx-auto -mb-3 flex w-full max-w-screen-xl flex-row justify-between gap-4 px-2 lg:-mb-5">
          <OfferAwareLink
            url={slice.primary.primary_button_link}
            className="w-full"
          >
            <Button color="primary" className="w-full max-w-64">
              <OfferAwareText text={slice.primary.primary_button_label} />
            </Button>
          </OfferAwareLink>

          <OfferAwareLink
            url={slice.primary.secondary_button_link}
            className="w-full"
          >
            <Button color="primary" className="w-full max-w-64">
              <OfferAwareText text={slice.primary.secondary_button_label} />
            </Button>
          </OfferAwareLink>
        </div>

        <div className="bg-cgc-green-60 w-full rounded-t-sm p-5 pb-2 lg:pb-5">
          <Paragraph className="text-cgc-blue font-bold">
            <OfferAwareText text={slice.primary.copy} />
          </Paragraph>
        </div>
      </div>
    </section>
  );
};

export default LandingPageFloatingButtons;
