import Link from "@/components/Link";
import * as prismicH from "@prismicio/helpers";
import { CommonComponent } from "@/components/types";
import { Heading, Paragraph } from "@cgc/ui";
import { clsx } from "@cgc/ui";

type ComponentProps = {
  isDark?: boolean;
};

export const leftComponents = ({ isDark }: ComponentProps) => ({
  heading1: ({ children }: CommonComponent) => {
    const props = {
      as: "div" as "div",
      className: clsx(
        "text-cgc-blue !mb-0 xl:text-left",
        isDark && "text-cgc-white",
      ),
    };

    return (
      <>
        <Heading {...props} size="h1" display mobileOnly>
          {children}
        </Heading>
        <Heading {...props} size="h2" display desktopOnly>
          {children}
        </Heading>
      </>
    );
  },
  paragraph: ({ children }: CommonComponent) => {
    return (
      <>
        <Heading.h2 as="div" display mobileOnly className="mb-0 xl:w-56">
          {children}
        </Heading.h2>
        <Heading.h4 as={"div"} display desktopOnly className="mb-0 xl:w-56">
          {children}
        </Heading.h4>
      </>
    );
  },
});

export const subcopyComponents = () => ({
  paragraph: ({ children }: CommonComponent) => (
    <>
      <Paragraph mobileOnly size="p2">
        {children}
      </Paragraph>
      <Paragraph desktopOnly size="p1">
        {children}
      </Paragraph>
    </>
  ),
});

export const rightComponents = ({ isDark }: ComponentProps) => ({
  heading2: ({ children }: CommonComponent) => (
    <Paragraph size="sub" className="mb-2">
      {children}
    </Paragraph>
  ),
  paragraph: ({ children }: CommonComponent) => (
    <>
      <Paragraph mobileOnly size="p3">
        {children}
      </Paragraph>
      <Paragraph desktopOnly size="p2">
        {children}
      </Paragraph>
    </>
  ),
  hyperlink: ({ node, children }: CommonComponent & { node: any }) => (
    <Link
      href={prismicH.asLink(node.data)!}
      className={clsx(isDark ? "text-cgc-white" : "primary")}
    >
      {children}
    </Link>
  ),
});

export const successMessageComponents = () => ({
  heading2: ({ children }: CommonComponent) => (
    <Paragraph size="sub" className={clsx("mb-2")}>
      {children}
    </Paragraph>
  ),
  paragraph: ({ children }: CommonComponent) => (
    <>
      <Paragraph mobileOnly size="p2">
        {children}
      </Paragraph>
      <Paragraph desktopOnly size="p1">
        {children}
      </Paragraph>
    </>
  ),
});
