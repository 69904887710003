"use client";
import { useEffect } from "react";
import { usePathname } from "next/navigation";
import { useQuery } from "@apollo/client/react/hooks";
import { GiftMembershipProductQuery } from "@/data/helpers/products/queries";
import { Product } from "@/types/gql/graphql";

function cleanId(id: string): number {
  return Number(id.split("/").slice(-1));
}

export default function GiftMembershipDataLayer() {
  const pathname = usePathname();
  const { data } = useQuery(GiftMembershipProductQuery, {
    variables: { handle: "gift-membership" },
  });
  const { title, productType, variants } = (data?.product ?? {}) as Product;
  const variant = (variants?.nodes ?? []).find((node) =>
    node.title.includes("4"),
  );
  const variantId = variant?.id && cleanId(variant.id);
  const variantPrice = variant?.price?.amount;

  useEffect(() => {
    if (
      [
        pathname === "/gifts", // added path check cause it might be called by slice preview in prismic
        variantId,
        title,
        productType,
        variantPrice,
      ].every((i) => !!i)
    ) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        id: variantId,
        name: title,
        category: productType,
        price: parseInt(variantPrice, 10),
      });
    }
  }, [variantId, title, productType, variantPrice]);

  return null;
}
