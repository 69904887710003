"use client";
import { useEffect } from "react";
import { useMutation } from "@apollo/client/react/hooks";
import { gql } from "@apollo/client/core";

export const NewsLetterSignupMutation = gql`
  mutation NewsletterSignup($input: OmetriaInput!) {
    newsletterSignup(input: $input)
  }
`;

export function useOnSubmit<InitialValuesT>({
  ometriaId,
}: {
  ometriaId: number;
}) {
  const [newsletterSignup, { data, loading }] = useMutation(
    NewsLetterSignupMutation,
  );
  const showSuccess = !!data?.newsletterSignup;

  const onSubmit = (values: InitialValuesT) => {
    newsletterSignup({
      variables: { input: { ...values, list: `${ometriaId}` } },
    });
  };

  useEffect(() => {
    if (data?.newsletterSignup) {
      if (window.ometria && window.ometria.identify) {
        window.ometria.identify(data.email);
      }
      localStorage.setItem("mailing_list", `${new Date().getTime()}`);
    }
  }, [data]);

  return { showSuccess, onSubmit, loading };
}
