"use client";
import React, { useMemo, useRef } from "react";
import ReactPlayer from "react-player";

import { EmbedVideoProps } from "./types";

// import dynamic from "next/dynamic";
// const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

const Video: React.FC<EmbedVideoProps> = ({
  id,
  url,
  width,
  height,
  // landscape = true,
  controls = true,
}) => {
  const container = useRef<HTMLDivElement | null>(null);
  const containerWidth = container.current?.offsetWidth;

  const sizes = useMemo(() => {
    if (!containerWidth) {
      return { width, height };
    }

    if (!width) {
      return { width: containerWidth, height };
    }

    if (width > containerWidth) {
      const ratio = containerWidth / width;

      return {
        width: containerWidth,
        height: height ? height * ratio : height,
      };
    }

    return { width, height };
  }, [
    // isMobile,
    containerWidth,
    width,
    height,
  ]);

  const props = {
    ...(sizes.width ? { width: sizes.width } : null),
    ...(sizes.height ? { height: sizes.height } : null),
  };

  return (
    <div id={id} ref={container} className="flex justify-center py-8">
      <ReactPlayer url={url} {...props} controls={controls} />
    </div>
  );
};

export default Video;
